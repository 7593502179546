import React from "react"
import { graphql, Link } from "gatsby"
import Containers from "../components/container"
import Header from "../components/header"
import Headertop from "../components/headertop"
import Footer from "../components/footer"
import Formfooter from "../components/form-footer"
import { Container, Col, Row } from "react-bootstrap"
import * as singleblogStyles from "../css/singleblog.module.css"
import { Icon } from '@iconify/react';
import chevronDoubleRight from '@iconify/icons-mdi-light/chevron-double-right';
import Img from "gatsby-image"
import Related from './related';
import Seo from "../components/seo"

import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  LineShareButton,
  LineIcon,
} from 'react-share'
import * as shareStyles from "../components/share-buttons/share.module.css"

const Posts = ({ data }) => {
  const post = data.markdownRemark;
  // Button Share Button
  const url = post.fields.slug;
  const siteUrl = data.site.siteMetadata.siteUrl
  const shareUrl = siteUrl + url
  const seoTitle = post.frontmatter.title
  const seoDescription = post.frontmatter.description
  const seoImage = post.frontmatter.featuredImage.childImageSharp.fluid
  return (
    <Containers>
          <Seo 
           title={seoTitle}
           description={seoDescription}
           image={seoImage}
           pathname={url}
           />
      <Headertop />
      <Header />
      <div className={singleblogStyles.hero}>
      <Container fluid="xl">
        <Row>
          <Col><h3 className={singleblogStyles.heroheader}>{post.frontmatter.title}</h3><p>{post.frontmatter.description}</p></Col>
        </Row>
      </Container>
      </div>
      <div className={singleblogStyles.herob}>
      <Container fluid="xl">
              <Row>
                  <Col><p><Link to="/">Home</Link> <Icon icon={chevronDoubleRight} /> <Link to="/blog/">Blog</Link> <Icon icon={chevronDoubleRight} /> {post.frontmatter.title}</p></Col>
              </Row>
      </Container>
      </div>
      <div className={singleblogStyles.herosection}>
      <Container fluid="xl">
        <Row>
          <Col md={8}>
            <h1 className={singleblogStyles.h1hero}>{post.frontmatter.title}</h1>
              <div className={shareStyles.divflex}>
                <p className={shareStyles.psharefont}>หากคุณพบว่าบทความนี้มีประโยชน์ โปรดแบ่งปัน</p>
                <FacebookShareButton url={shareUrl}>
                      <FacebookIcon size={35} round={true} className={shareStyles.sharebuttoms} />
                </FacebookShareButton>
                <TwitterShareButton url={shareUrl}>
                      <TwitterIcon  size={35} round={true} className={shareStyles.sharebuttoms} />
                </TwitterShareButton>
                <LinkedinShareButton url={shareUrl}>
                  <LinkedinIcon  size={35} round={true} className={shareStyles.sharebuttoms}/>
                </LinkedinShareButton>
                <LineShareButton url={shareUrl}>
                    <LineIcon  size={35} round={true} className={shareStyles.sharebuttoms}/>
                </LineShareButton>
              </div>
            <Img fluid={post.frontmatter.featuredImage.childImageSharp.fluid} className={singleblogStyles.imgproduct} />
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
              <div className={shareStyles.divflex}>
                <p  className={shareStyles.psharefont}>หากคุณพบว่าบทความนี้มีประโยชน์ โปรดแบ่งปัน</p>
                <FacebookShareButton url={shareUrl}>
                      <FacebookIcon size={35} round={true} className={shareStyles.sharebuttoms} />
                </FacebookShareButton>
                <TwitterShareButton url={shareUrl}>
                      <TwitterIcon  size={35} round={true} className={shareStyles.sharebuttoms} />
                </TwitterShareButton>
                <LinkedinShareButton url={shareUrl}>
                  <LinkedinIcon  size={35} round={true} className={shareStyles.sharebuttoms}/>
                </LinkedinShareButton>
                <LineShareButton url={shareUrl}>
                    <LineIcon  size={35} round={true} className={shareStyles.sharebuttoms}/>
                </LineShareButton>
              </div>
          </Col>
          <Col md={4}>
            <div className={singleblogStyles.backgroundpost}>
              <h3 style={{color: '#050248', paddingBottom: '20px'}} >บทความที่เกี่ยวข้อง</h3>
              <Related slug={post.fields.slug} />
            </div></Col>
        </Row>
      </Container>
      </div>
      <Formfooter />
      <Footer />
    </Containers>
  );
};

export default Posts;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        tags
        date(formatString: "MMMM DD, YYYY")
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`