import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Containers from '../components/container';
import {Row, Col} from "react-bootstrap"


const Related = ( props ) => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
            filter: { frontmatter: { key: { eq: "blog" }}}
            sort: { fields: [frontmatter___date], order: DESC }
          ) {
              edges {
                  node {
                    id
                    frontmatter {
                      title
                      date(formatString: "MMMM DD, YYYY")
                      description
                      featuredImage {
                        childImageSharp {
                          fluid(maxWidth: 400) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                    fields {
                      slug
                    }
                    excerpt
                  }
                }
              }
            }
          `
  );

  let count = 0;
  const posts = allMarkdownRemark.edges.filter(({ node }) => {
    const found = node.frontmatter
    return node.fields.slug !== props.slug && found && count++ < 3;
  });

  return posts && posts.length > 0 ? (
        <Containers>
        {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            const image = node.frontmatter.featuredImage.childImageSharp.fluid
            const link = node.fields.slug
            return  <Row key={node.fields.slug}>
            <Col md={4} xs={4}><Link to={link}><Img fluid={image} /></Link></Col>
            <Col md={8} xs={8}><Link to={link}><p dangerouslySetInnerHTML={{__html: title}} /></Link></Col>      
            </Row>
        })}
        </Containers>
  ) : null;
};

export default Related;